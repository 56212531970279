<template>
  <master-layout smallTitle="il tuo" bigTitle="Feed">
    <trCard
      v-for="item in feedItems"
      :key="item.id"
      card-type="white"
      :img="true"
      sideWidth="sm"
      class="mb-4"
    >
      <template #main>
        <!-- a template to show the info in feedItems -->
        <div class="main py-3">
          <div class="d-flex align-items-center">
            <img
              :src="item.avatar_url"
              class="rounded-circle me-2"
              width="40"
              height="40"
              @error="handleError"
            />
            <h5 class="mb-0 ml-2">{{ item.name }}</h5>
          </div>
          <p class="text-14 mt-2 mb-0">{{ item.text }}</p>
        </div>
      </template>
      <template #right-side>
        <!-- a template to show an image representing the item.type -->
        <div
          class="right-side d-flex align-items-center justify-content-center"
        >
          <img
            v-if="item.type === 'like'"
            src="https://img.icons8.com/ios/50/000000/like--v1.png"
            width="30"
            height="30"
            @error="handleError"
          />
          <img
            v-else-if="item.type === 'comment'"
            src="https://img.icons8.com/ios/50/000000/topic.png"
            width="30"
            height="30"
            @error="handleError"
          />
        </div>
      </template>
    </trCard>
  </master-layout>
</template>

<script setup>
import trCard from "@/components/Nuovi/trCard.vue";

const feedItems = [
  {
    id: 1,
    name: "Filippo Grassi",
    avatar_url: "https://avatars.githubusercontent.com/u/56132780?v=4",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum tellus, imperdiet.",
    type: "like",
  },
  {
    id: 2,
    name: "Maria Veronesi",
    avatar_url: "https://avatars.githubusercontent.com/u/56132780?v=4",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum tellus, imperdiet.",
    type: "comment",
  },
  {
    id: 3,
    name: "Giacomo Fabbri",
    avatar_url: "https://avatars.githubusercontent.com/u/56132780?v=4",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum tellus, imperdiet.",
    type: "like",
  },
];

function handleImgError() {
  return "https://placehold.co/300x300?text=MartechRevolution";
}
</script>

<style scoped>
.rounded-circle {
  border-radius: 50%;
}
</style>
